/*------------- #STYLES for INFO BOXES --------------*/


.crumina-info-box {
  display: flex;
  flex-direction: column;
  text-align: center;
  transition: all .3s ease;
}

.info-box-thumb {
  position: relative;
  transition: all .3s ease;

  .puzzle-icon {
	display: block;
  }
}

.info-box-content {
  position: relative;
}

.info-box-title {
  display: block;
  word-break: break-all;
}

.read-more--with-arrow {
  display: inline-flex;
  align-items: center;
  font-weight: 700;

  &:hover {
	color: var(--primary-accent-color);

	.crumina-icon {
	  fill: var(--primary-accent-color);
	}
  }

  .crumina-icon {
	margin-left: 10px;
	width: 14px;
	height: 9px;
	min-width: 14px;
	min-height: 9px;
	fill: var(--dark-themes);
  }
}

.read-more--with-arrow-left {
  .crumina-icon {
	margin-left: 0;
	margin-right: 10px;
	fill: inherit;
  }
}

.read-more--with-arrow-down {
  display: inline-flex;
  flex-direction: column;
  font-weight: 700;
  align-items: center;

  &:hover {
	color: var(--primary-accent-color);

	.crumina-icon {
	  fill: var(--primary-accent-color);
	}
  }

  .crumina-icon {
	margin-top: 10px;
	width: 16px;
	height: 9px;
	fill: var(--orange-themes);
  }
}

.contact-info {
  font-weight: 700;
  font-size: 18px;
  color: var(--primary-accent-color);
  display: flex;
  align-items: center;

  .crumina-icon {
	fill: var(--border-grey-color);
	margin-right: 10px;
	width: 20px;
	height: 20px;
  }
}


/*------------- #INFO BOX STANDARD --------------*/

.info-box--standard {
  .info-box-thumb {
	margin-bottom: 30px;
  }

  .info-box-text {
	margin-bottom: 0;
  }
}

/*------------- #INFO BOX ICON LEFT --------------*/

.info-box--icon-left {
  text-align: left;

  .info-box-thumb-title-wrap {
	display: flex;
	align-items: center;
	margin-bottom: 30px;
  }

  .info-box-thumb {
	margin-right: 30px;
  }

  .info-box-title {
	margin-bottom: 0;
  }
}


/*------------- #INFO BOX THUMB LEFT --------------*/

.info-box--thumb-left {
  text-align: left;
  display: flex;
  align-items: flex-start;
  flex-direction: row;

  .info-box-text {
	&:last-child {
	  margin-bottom: 0;
	}
  }

  .info-box-thumb {
	min-width: 100px;
	margin-right: 30px;
  }
}


/*------------- #INFO BOX align LEFT --------------*/

.info-box--align-left {
  text-align: left;

  .info-box-thumb {
	margin-bottom: 30px;
  }
}


/*------------- #INFO BOX inline --------------*/

.info-box--inline {
  display: flex;
  align-items: center;
  flex-direction: row;
  text-align: left;

  .info-box-text {
	font-size: 18px;
	font-weight: 500;

	&:last-child {
	  margin-bottom: 0;
	}
  }

  .info-box-thumb {
	margin-right: 30px;
	min-width: max-content;
  }
}


/*------------- #INFO BOX with number --------------*/

.info-box--with-number {
  text-align: left;
  flex-direction: row;
  padding: 40px;
  border: 2px solid #dfe6ec;
  border-radius: 5px;

  &:hover {
	border-color: var(--lime-themes);
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .info-box-title {
	font-size: 60px;
	font-weight: 700;
	color: var(--lime-themes);
	margin-right: 40px;
	min-width: 10%;
	line-height: 1;
  }

  .info-box-text {
	font-weight: 500;

	&:last-child {
	  margin-bottom: 0;
	}
  }
}


/*------------- #INFO-BOX--ICON-SCALED --------------*/

.info-box--icon-scaled {

  .info-box-title {
	margin-bottom: 30px;
  }

  .info-box-thumb {
	margin-bottom: 30px;
  }

  .info-box-text {

	&:last-child {
	  margin-bottom: 0;
	}
  }
}


/*------------- #INFO-BOX--WITH BG --------------*/

.info-box--with-bg {
  padding: 60px;
  background-color: var(--white-color);
  border-radius: 5px;

  &:hover {
	box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  }

  .info-box-thumb {
	margin-bottom: 30px;
  }
}


/*------------- #INFO-BOX--bordered --------------*/

.info-box--bordered {
  padding: 30px;
  border-radius: 5px;
  border: 2px solid #dfe6ec;
  flex-direction: row;
  text-align: left;
  margin: 30px 0;

  .info-box-thumb {
	margin-right: 30px;
	min-width: max-content;
  }
}


/*------------- #NOTICE INFO --------------*/

.notice-info {
  display: flex;
  align-items: flex-start;
  padding: 30px;
  border-radius: 5px;
  background-color: #d1f2ff;
  margin-bottom: 20px;

  p {
	margin-bottom: 0;
  }
}

.notice-info-thumb {
  margin-right: 10px;
  min-width: max-content;

  .crumina-icon {
	width: 16px;
	height: 16px;
  }
}

.notice-info--lihgt-blue {
  background-color: #d1f2ff;
}

.notice-info--lihgt-yellow {
  background-color: #d1f2ff;
}

.notice-info--light-yellow {
  background-color: #fff0ac;
}


/*------------- Responsive Mode --------------*/

@media (max-width: 1024px) {
  .info-box--with-bg {
	padding: 20px;
  }
}

@media (max-width: 768px) {
  .info-box--icon-scaled .info-box-title {
	margin-bottom: 15px;
  }

  .info-box--icon-scaled .info-box-thumb {
	margin-bottom: 15px;
  }

  .info-box--inline {
	flex-direction: column;
	text-align: center;
  }

  .info-box--inline .info-box-thumb {
	margin-right: 0;
	margin-bottom: 20px;
  }

  .info-box--thumb-left {
	flex-direction: column;
  }

  .info-box--thumb-left .info-box-thumb {
	margin-right: 0;
	margin-bottom: 20px;
  }

  .info-box--with-number {
	padding: 20px;
  }

  .info-box--with-number .info-box-title {
	font-size: 30px;
	margin-right: 15px;
  }

  .info-box--bordered {
	padding: 15px;
	flex-direction: column;
  }

  .info-box--bordered .info-box-thumb {
	margin-right: 0;
	margin-bottom: 15px;
  }
}